<template>
  <div>
    <category-manager
      :group-category="1"
      :btn-add-title="'Thêm mới nhóm tin tức'"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import CategoryManager from '@/views/category-manager/group-new/pages/category-manager.vue'

export default {
  components: {
    CategoryManager,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
